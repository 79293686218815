import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import tuiFlyRouteService from 'shared/services/tuiFlyRoute';
import { SORT_ASC, SORT_DESC } from 'shared/services/constants';
import {
  defaultItems,
  setItemsResult,
  setLoading,
  setError,
  appErrorHandler,
  resetItems,
  setActionPayloadItems,
} from 'shared/store/StoreFunctions';

export const getAll = createAsyncThunk(
  'tuiFlyRoutesList/getAll',
  async ({ params }, { rejectWithValue }) => {
    try {
      return await tuiFlyRouteService.getAll(params);
    } catch (error) {
      return rejectWithValue(appErrorHandler(error));
    }
  }
);

export const tuiFlyRoutesListSlice = createSlice({
  name: 'tuiFlyRoutesList',
  initialState: {
    lastOpened: {
      id: null,
    },
    list: { ...defaultItems },
    params: {
      priceAverage: true,
      tuiFlyUrl: true,
      latestPrice: true,
      sort: 'tuiFlyPrice',
      sortOrder: SORT_ASC,
      // search: '',
      // count: true,
      // sort: 'activeTime',
      // sortOrder: SORT_DESC,
      itemsPerPage: 100,
    },
  },
  reducers: {
    setParams: (state, action) => setActionPayloadItems(state.params, action),
    resetGetAll: resetItems,
  },
  extraReducers: builder => {
    builder
      .addCase(getAll.pending, (state, action) =>
        setLoading(state.list, action)
      )
      .addCase(getAll.fulfilled, (state, action) =>
        setItemsResult(state.list, action)
      )
      .addCase(getAll.rejected, (state, action) =>
        setError(state.list, action)
      );
  },
});

export const { setLastOpened, setParams, resetGetAll } =
  tuiFlyRoutesListSlice.actions;

export default tuiFlyRoutesListSlice.reducer;
