import { configureStore } from '@reduxjs/toolkit';

import tuiFlyRoutesList from 'shared/reducers/tuiFlyRoutesList';

export const store = configureStore({
  reducer: {
    tuiFlyRoutesList,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
