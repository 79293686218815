import { find } from 'lodash';
import moment from 'moment';

export const rootObject = {
  error: null,
  loading: false,
  loaded: null,
};

export const defaultItems = {
  items: null,
  itemsCount: null,
  pagesCount: null,
  options: null,
  ...rootObject,
};

export const defaultItem = {
  item: null,
  ...rootObject,
};

// General purpose store actions
export const setLoading = (state, action) => {
  // console.log('storeFunction.setLoading', state, action);
  state.loading = true;
};

export const setError = (state, action) => {
  console.log('storeFunction.setError', state, action);
  state.loading = false;
  state.error = action.payload.httpError ?? 'unknown_error';
};

// Manipulation of a "single item" in store
export const setItemResult = (state, action) => {
  // console.log('storeFunction.setItemResult', state, action);
  state.loading = false;
  state.loaded = moment().unix();
  state.item = action.payload.item;
  state.error = null;
};

export const resetItem = (state, action) => {
  for (const key in defaultItem) {
    state[key] = defaultItem[key];
  }
};

export const itemIsSet = state => {
  return (
    state !== undefined &&
    state.item !== undefined &&
    state.loading !== undefined &&
    state.item !== null &&
    state.loading === false
  );
};

// Manipulation of multiple store items
export const setItemsResult = (state, action) => {
  state.loading = false;
  state.loaded = moment().unix();
  state.items = action.payload.items;
  state.itemsCount = action.payload.itemsCount ?? null;
  state.options = action.payload.options ?? null;
  state.pagesCount =
    state.itemsCount != null && state.options.itemsPerPage != null
      ? Math.ceil(state.itemsCount / state.options.itemsPerPage)
      : null;
  state.error = null;
};

export const pushToItems = (state, action, newObject) => {
  state.items =
    state.items === null ? [newObject] : [...state.items, newObject];
  state.loaded = moment().unix();
};

export const getItemsItemById = (state, id) => {
  return find(state.items, item => item.id === id);
};

export const updateItemsItemById = (state, id, newObject) => {
  state.items = state.items.map(item => (item.id === id ? newObject : item));
  state.loaded = moment().unix();
};

export const updateItemsItemByIndex = (state, index, newObject) => {
  state.items = [...state.items];
  state.items[index] = newObject;
  state.loaded = moment().unix();
};

export const setActionPayloadItems = (state, action) => {
  for (const key in action.payload) {
    state[key] = action.payload[key];
  }
};

export const resetItems = state => {
  for (const key in defaultItems) {
    state[key] = defaultItems[key];
  }
};

// Async thunk error handler
// If a HTTP request was executed, get the HTTP Response Status
// + "exception" key in the result
// This "Exception" key can be used to show granular errors on the front
export const appErrorHandler = error => {
  if (error.response === undefined) {
    // If no (valid) response is defined,
    // mark the error as a "Service Unavailable" error
    return {
      error: error,
      httpCode: 503,
      httpError: 'service_unavailable',
    };
  } else if (
    error.response !== undefined &&
    error.response.status !== 200 &&
    error.response.data.exception !== undefined
  ) {
    return {
      error: error,
      httpCode: error.response.status,
      httpError:
        // Only use the API exception if it's a valid API "error_code"
        // (which shouldnt' include a space and should probably always
        // include at least one underscore)
        error.response.data.exception.indexOf(' ') === -1 &&
        error.response.data.exception.indexOf('_') > -1
          ? error.response.data.exception
          : 'unknown_error',
    };
  }

  return { error };
};
