import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Disclosure } from '@headlessui/react';
import { ExternalLinkIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { hasFlag } from 'country-flag-icons';
import NumberFormat from 'react-number-format';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import {
  getAll,
  resetGetAll,
  setParams,
} from 'shared/reducers/tuiFlyRoutesList';
import LogoWhite from 'assets/img/LogoWhite.svg';

const navigation = [{ name: 'TUI Fly', href: '#', current: true }];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function AveragePercentageDiff({ current = 0, average = 0, ...props }) {
  if (average == current) {
    return null;
  } else if (average > current) {
    const avgPercentageDiff = (1 - average / current) * 100;
    return (
      <NumberFormat
        className="font-semibold text-green-500"
        displayType="text"
        decimalSeparator=","
        suffix="%"
        decimalScale={2}
        value={avgPercentageDiff}
      />
    );
  } else if (average < current) {
    const avgPercentageDiff = (current / average - 1) * 100;
    return (
      <NumberFormat
        className="font-semibold text-red-500"
        displayType="text"
        decimalSeparator=","
        prefix="+"
        suffix="%"
        decimalScale={2}
        value={avgPercentageDiff}
      />
    );
  }
}

export default function AuctionsPage() {
  // Redux + local state
  const dispatch = useDispatch();
  const { lastOpened, list, params } = useSelector(
    store => store.tuiFlyRoutesList
  );

  //
  const navigate = useNavigate();

  // On page unload, remove the list of clients from store
  useEffect(() => {
    return () => {
      dispatch(resetGetAll());
    };
  }, [dispatch]);

  // When the params property changes, refresh the list of clients
  useEffect(() => {
    dispatch(getAll({ params }));
  }, [params, dispatch]);

  // Event handlers
  const handleSortableHeaderOnClick = (sort, sortOrder) => {
    dispatch(setParams({ ...params, sort, sortOrder }));
  };

  const handleSearchUpdate = search => {
    if (search !== params.search) {
      dispatch(setParams({ ...params, search, page: 1 }));
    }
  };

  const handlePageChange = page => {
    dispatch(setParams({ ...params, page }));
  };

  console.log('list', list);

  return (
    <div className="min-h-full">
      <Helmet>
        <title>TUI Fly - {process.env.REACT_APP_TITLE}</title>
      </Helmet>

      <Disclosure as="nav" className="bg-indigo-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img className="h-8" src={LogoWhite} alt="Workflow" />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map(item => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-indigo-900 text-white'
                              : 'text-gray-300 hover:bg-indigo-700 hover:text-white',
                            'rounded-md px-3 py-2 text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}>
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-indigo-800 p-2 text-gray-400 hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                {navigation.map(item => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-indigo-900 text-white'
                        : 'text-gray-300 hover:bg-indigo-700 hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}>
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <main>
        <div className="mx-auto max-w-7xl py-5 sm:px-6 lg:px-8">
          {((list.loading === true && list.items === null) ||
            list.items === null) && <div>Loading</div>}
          {list.error !== null && <div>Something went wrong...</div>}
          {list.items !== null && (
            <div className="shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 ">
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6">
                      To
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                      From
                    </th>
                    <th
                      title="Current price"
                      scope="col"
                      className="sticky top-0 z-10 whitespace-nowrap border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                      Current
                    </th>
                    <th
                      title="Average price"
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                      Avg.
                    </th>
                    <th
                      title="Price difference"
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                      Diff.
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {list.items.map(route => (
                    <tr key={route.id}>
                      <td className="w-full max-w-0 py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                        <span className="mr-1 text-lg">
                          {route.getAirportTo().countryCode != '' &&
                            hasFlag(route.getAirportTo().countryCode) &&
                            getUnicodeFlagIcon(
                              route.getAirportTo().countryCode
                            )}
                        </span>
                        {route.getAirportTo().title}
                        {/* <dl className="font-normal lg:hidden">
                          <dt className="sr-only">Title</dt>
                          <dd className="mt-1 truncate text-gray-700">
                            {route.title}
                          </dd>
                          <dt className="sr-only sm:hidden">Email</dt>
                        </dl> */}
                      </td>
                      <td className="hidden px-3 py-2 text-sm text-gray-900 lg:table-cell">
                        <span className="mr-1 text-lg">
                          {route.getAirportFrom().countryCode != '' &&
                            hasFlag(route.getAirportFrom().countryCode) &&
                            getUnicodeFlagIcon(
                              route.getAirportFrom().countryCode
                            )}
                        </span>
                        {route.getAirportFrom().title}
                      </td>
                      <td className="px-3 py-2 text-sm text-gray-900">
                        <NumberFormat
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="€"
                          value={route.getLatestPrice().price}
                        />
                      </td>
                      <td className="hidden px-3 py-2 text-sm text-gray-900 lg:table-cell">
                        <NumberFormat
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="€"
                          value={route.getAveragePrice()}
                        />
                      </td>
                      <td className="hidden px-3 py-2 text-sm text-gray-900 lg:table-cell">
                        <AveragePercentageDiff
                          current={route.getLatestPrice().price}
                          average={route.getAveragePrice()}
                        />
                      </td>
                      <td className="py-2 pl-3 pr-4 sm:pr-6">
                        <div className="flex justify-end">
                          <a
                            href={route.getTuiFlyUrl().getUrl()}
                            target="_blank"
                            className="text-indigo-600 hover:text-indigo-900">
                            <ExternalLinkIcon className="h-4 w-4" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
