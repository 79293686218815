import TuiFlyRouteModel from 'shared/models/TuiFlyRouteModel';
import restService from 'shared/services/rest.js';

export async function getAll(params) {
  const result = await restService.getAll(
    `${process.env.REACT_APP_API_URL}/tuiflyroutes`,
    params
  );
  result.items = result.items.map(item => new TuiFlyRouteModel(item));
  return result;
}

const defaultExport = { getAll };

export default defaultExport;
