import axios from 'axios';
import { getHeadersToken } from 'auth/token';

const headers = {
  'X-Bandoru-Application': process.env.REACT_APP_BANDORU_APPLICATION,
  'X-Bandoru-Version': process.env.REACT_APP_BANDORU_APPLICATION_VERSION,
};

export async function getAll(url, params) {
  console.log(`GET ${url}`);

  return (
    await axios(url, {
      method: 'GET',
      params,
      headers: { ...headers, ...getHeadersToken() },
    })
  ).data;
}

export async function getOne(url, params) {
  console.log(`GET ${url}`);

  return (
    await axios(url, {
      method: 'GET',
      params,
      headers: { ...headers, ...getHeadersToken() },
    })
  ).data;
}

export async function addOne(url, data) {
  console.log(`POST ${url}`);

  return (
    await axios(url, {
      method: 'POST',
      data,
      headers: { ...headers, ...getHeadersToken() },
    })
  ).data;
}

const defaultExport = { getAll, getOne, addOne };

export default defaultExport;
