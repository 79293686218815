export function getAuthToken() {}

export function getBrowserToken() {
  if (localStorage.getItem('cms_auth_token')) {
    return localStorage.getItem('cms_auth_token');
  }
  if (localStorage.getItem('client_auth_token')) {
    return localStorage.getItem('client_auth_token');
  }
  return null;
}

export function getHeadersToken() {
  return getBrowserToken() != null
    ? {
        Authorization: 'Bearer ' + getBrowserToken(),
      }
    : {};
}

export function setCmsAuthToken(newToken) {
  if (newToken == undefined || newToken == null) {
    localStorage.removeItem('cms_auth_token');
  } else {
    console.log('localStorage.setItem(cms_auth_token)');
    localStorage.setItem('cms_auth_token', newToken);
  }
}

export function setClientAuthToken(newToken) {
  if (newToken == undefined || newToken == null) {
    localStorage.removeItem('client_auth_token');
  } else {
    console.log('localStorage.setItem(client_auth_token)');
    localStorage.setItem('client_auth_token', newToken);
  }
}
