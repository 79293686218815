export default class BaseModel {
  constructor(jsonHash) {
    if (typeof jsonHash === 'object') {
      this.mergeData(jsonHash);
    }
  }

  mergeData(jsonHash) {
    for (var attrname in jsonHash) {
      this[attrname] = jsonHash[attrname];
    }
  }

  newObject(jsonHash) {
    return new this.constructor(jsonHash);
  }
}
