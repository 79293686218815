import moment from 'moment';
import Model from 'shared/models/BaseModel';
import AirportModel from 'shared/models/AirportModel';
import TuiFlyUrlModel from 'shared/models/TuiFlyUrlModel';
import TuiFlyPriceModel from 'shared/models/TuiFlyPriceModel';

export default class TuiFlyRouteModel extends Model {
  getAirportFrom() {
    return this._airportFrom ? new AirportModel(this._airportFrom) : null;
  }

  getAirportTo() {
    return this._airportTo ? new AirportModel(this._airportTo) : null;
  }

  getTuiFlyUrl() {
    return this._tuiFlyUrl ? new TuiFlyUrlModel(this._tuiFlyUrl) : null;
  }

  getLatestPrice() {
    return this._latestPrice ? new TuiFlyPriceModel(this._latestPrice) : null;
  }

  getAveragePrice() {
    return this._priceAverage ? parseFloat(this._priceAverage) : null;
  }
}
